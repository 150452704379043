import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import infraLayer from "@/infra/vue-plugin";

Vue.config.productionTip = false;

import LayoutDefault from "./layouts/default";
import LayoutFullScreen from "./layouts/full-screen";

Vue.component("layout-default", LayoutDefault);
Vue.component("layout-full-screen", LayoutFullScreen);

Vue.use(infraLayer);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
