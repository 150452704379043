import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/index.vue"),
    meta: {
      layout: "layout-full-screen",
      requiresAuth: true,
    },
    redirect: { name: "offers" },
  },
  {
    path: "/offers",
    name: "offers",
    component: () =>
      import(/* webpackChunkName: "offers" */ "@/views/offers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/offers/add",
    name: "offer-create",
    props: {
      isCreate: true,
    },
    component: () =>
      import(/* webpackChunkName: "offer" */ "@/views/offer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/offers/:id",
    name: "offer-edit",
    props: {
      isCreate: false,
    },
    component: () =>
      import(/* webpackChunkName: "offer" */ "@/views/offer.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("offers/read", to.params.id).then((offer) => {
        to.meta.formData = offer;
        next();
      });
    },
  },
  {
    path: "/showcases",
    name: "showcases",
    component: () =>
      import(/* webpackChunkName: "showcases" */ "@/views/showcases.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/showcases/add",
    name: "showcase-create",
    props: {
      isCreate: true,
    },
    component: () =>
      import(/* webpackChunkName: "showcase" */ "@/views/showcase.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/showcases/:id",
    name: "showcase-edit",
    props: {
      isCreate: false,
    },
    component: () =>
      import(/* webpackChunkName: "showcase" */ "@/views/showcase.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("showcases/read", to.params.id).then((project) => {
        to.meta.formData = project;
        next();
      });
    },
  },
  {
    path: "/showcases/:id/offers",
    name: "showcase-offers",
    component: () =>
      import(
        /* webpackChunkName: "showcase-offers" */ "@/views/showcase-offers.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("showcaseOffers/get", to.params.id).then(() => {
        next();
      });
    },
  },
  {
    path: "/login",
    name: "auth",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login.vue"),
    meta: {
      layout: "layout-full-screen",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next();
      return;
    }

    store
      .dispatch("auth/getUser")
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: "auth" });
      });
  } else {
    next();
  }
});
export default router;
