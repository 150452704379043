export default {
  namespaced: true,
  state: () => ({
    showcases: [],
  }),
  actions: {
    getList({ commit }) {
      return this.$infra.adminApi.projects
        .list()
        .then((response) => {
          if ((response || {}).successful) {
            commit("setShowcases", response.result);

            return;
          }

          throw response;
        })
        .catch((res) => {
          if (res.message) {
            alert(res.message);
          }

          console.error("projects list: ", res);

          throw res;
        });
    },
    create(ctx, { title, link }) {
      return this.$infra.adminApi.projects
        .create({
          title,
          link,
        })
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
    delete(ctx, id) {
      return this.$infra.adminApi.projects
        .delete({
          id,
        })
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
    read(ctx, id) {
      return this.$infra.adminApi.projects.read(id);
    },
    update(ctx, { id, title, link }) {
      return this.$infra.adminApi.projects
        .update({
          id,
          title,
          link,
        })
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
  },
  mutations: {
    setShowcases(state, showcases) {
      state.showcases = showcases;
    },
  },
  getters: {
    list(state) {
      return state.showcases;
    },
  },
};
