const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: localStorage ? localStorage.getItem("token") : "",
  };
};

export default {
  // Авторизация
  auth: {
    login({ login, password }) {
      return fetch("/admin-api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login, //admin
          password, //admin-password
        }),
      }).then((res) => {
        if (localStorage.setItem && res.headers.get("authorization")) {
          localStorage.setItem("token", res.headers.get("authorization"));
        }

        return res.json();
      });
    },
    user() {
      return fetch("/admin-api/auth/user", {
        headers: getHeaders(),
      }).then((res) => res.json());
    },
  },
  // Витрины - showcases
  projects: {
    list() {
      return fetch("/admin-api/projects", {
        headers: getHeaders(),
      }).then((res) => res.json());
    },
    create({ title, link }) {
      return fetch("/admin-api/projects", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          title,
          link,
        }),
      }).then((res) => res.json());
    },
    read(id) {
      return fetch("/admin-api/projects/" + id, {
        headers: getHeaders(),
      })
        .then((res) => res.json())
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        });
    },
    update({ id, title, link }) {
      return fetch("/admin-api/projects/" + id, {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify({
          title,
          link,
        }),
      }).then((res) => res.json());
    },
    delete({ id }) {
      return fetch("/admin-api/projects/" + id, {
        method: "DELETE",
        headers: getHeaders(),
      }).then((res) => res.json());
    },
    setOffers(id, offers) {
      offers.forEach((item, index) => {
        item.weight = index;
      });

      return fetch("/admin-api/projects/" + id + "/offers", {
        method: "PATCH",
        headers: getHeaders(),
        body: JSON.stringify({
          offers,
        }),
      }).then((res) => res.json());
    },
  },
  offers: {
    list() {
      return fetch("/admin-api/offers", {
        headers: getHeaders(),
      })
        .then((res) => res.json())
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        });
    },
    //https://learn.javascript.ru/formdata
    create(body) {
      return fetch("/admin-api/offers", {
        method: "POST",
        headers: {
          Authorization: localStorage ? localStorage.getItem("token") : "",
        },
        body,
      }).then((res) => res.json());
    },
    read(id) {
      return fetch("/admin-api/offers/" + id, {
        headers: getHeaders(),
      }).then((res) => res.json());
    },
    update(id, body) {
      return fetch("/admin-api/offers/" + id, {
        method: "POST",
        headers: {
          Authorization: localStorage ? localStorage.getItem("token") : "",
        },
        body,
      }).then((res) => res.json());
    },
    delete(id) {
      return fetch("/admin-api/offers/" + id, {
        method: "DELETE",
        headers: getHeaders(),
      }).then((res) => res.json());
    },
  },
};
