export default {
  namespaced: true,
  state: () => ({
    offers: [],
  }),
  actions: {
    getList({ commit }) {
      return this.$infra.adminApi.offers
        .list()
        .then((response) => {
          commit("setOffers", response);
        })
        .catch((res) => {
          if (res.message) {
            alert(res.message);
          }

          console.error("offers list: ", res);

          throw res;
        });
    },
    create(ctx, formData) {
      return this.$infra.adminApi.offers
        .create(formData)
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
    read(ctx, id) {
      return this.$infra.adminApi.offers
        .read(id)
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
    update(ctx, { id, formData }) {
      return this.$infra.adminApi.offers
        .update(id, formData)
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
    delete(ctx, id) {
      return this.$infra.adminApi.offers
        .delete(id)
        .then((response) => {
          if ((response || {}).successful) {
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          throw res;
        });
    },
  },
  mutations: {
    setOffers(state, offers) {
      state.offers = offers;
    },
  },
  getters: {
    list(state) {
      return state.offers;
    },
  },
};
