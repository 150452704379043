import Vue from "vue";
import $infra from "@/infra";
import Vuex from "vuex";
import auth from "./modules/auth";
import offers from "./modules/store-offers";
import showcases from "./modules/store-showcases";
import showcaseOffers from "./modules/store-showcase-offers";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, offers, showcases, showcaseOffers },
});

store.$infra = $infra;

export default store;
