const arrParams = [];

export default {
  namespaced: true,
  state: () => ({
    id: null,
    title: "",
    link: "#",
    offers: [],
    project_offers: [],
  }),
  actions: {
    get({ commit, dispatch }, id) {
      commit("setId", id);
      return dispatch("updateState");
    },
    updateState({ state, commit }) {
      return Promise.all([
        this.$infra.adminApi.offers.list(),
        this.$infra.adminApi.projects.read(state.id),
      ]).then((responses) => {
        const [offers, project] = responses;

        commit("setProject", {
          offers,
          project,
        });
      });
    },
    pushOffers({ state, dispatch }, item) {
      const offers = [...state.project_offers];
      offers.push({ offer_id: item.id, weight: 0, is_featured: false });

      return this.$infra.adminApi.projects
        .setOffers(state.id, offers)
        .then(() => {
          dispatch("updateState");
        });
    },
    popOffers({ state, dispatch }, item) {
      const offers = state.project_offers.filter(
        (offer) => offer.id != item.id
      );

      return this.$infra.adminApi.projects
        .setOffers(state.id, offers)
        .then(() => {
          dispatch("updateState");
        });
    },
    sortOffers({ state, commit }, { newIndex, oldIndex }) {
      const offers = [...state.project_offers];
      const draggableElem = offers[oldIndex];

      offers.splice(oldIndex, 1);
      offers.splice(newIndex, 0, draggableElem);

      arrParams.push([state.id, offers]);

      let isStart = false;
      const runSetOffers = (arr) => {
        if (arr.length && !isStart) {
          isStart = true;
          const [id, off] = arr.shift();

          this.$infra.adminApi.projects.setOffers(id, off).then(() => {
            isStart = false;
            runSetOffers(arr);
          });
        }
      };

      runSetOffers(arrParams);

      commit("setProjectOffers", offers);
    },
    chnageIsFeaturedOffer({ state }, { id, is_featured }) {
      const offers = [...state.project_offers];

      offers.forEach((offer) => {
        if (offer.id == id) {
          offer.is_featured = is_featured;
        }
      });

      return this.$infra.adminApi.projects.setOffers(state.id, offers);
    },
  },
  mutations: {
    setId(state, id) {
      state.id = id;
    },
    setProjectOffers(state, offers) {
      state.project_offers = offers;
    },
    setProject(state, { project, offers }) {
      const offersIds = [];
      state.id = project.id;
      state.link = project.link;
      state.title = project.title;
      state.project_offers = project.project_offers.map((item) => {
        item.offer = offers.find((offer) => {
          if (offer.id == item.offer_id) {
            offersIds.push(offer.id);
            return true;
          }
        });
        return item;
      });
      state.offers = offers.filter(
        (offer) => offersIds.indexOf(offer.id) === -1
      );
    },
  },
  getters: {
    showcase(state) {
      return {
        id: state.id,
        title: state.title,
        link: state.link,
        offers: state.offers,
        project_offers: state.project_offers,
      };
    },
  },
};
