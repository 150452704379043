<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> offerswitcher </v-list-item-title>
          <v-list-item-subtitle> админка </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in showcasesList"
          :key="item.title"
          link
          :to="'/showcases/' + item.id + '/offers'"
        >
          <v-list-item-icon>
            <v-icon>mdi-ray-start</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-avatar>
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
            <strong>{{ login }}</strong>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="onClickLogout">
            <v-list-item-content>
              <v-list-item-title class="text-h6"> Выйти </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LayoutDefault",

  data: () => ({
    drawer: true,
    items: [
      { title: "Офферы", icon: "mdi-view-list", link: "/offers" },
      { title: "Витрины", icon: "mdi-wall", link: "/showcases" },
    ],
  }),

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      login: "auth/login",
      showcasesList: "showcases/list",
    }),
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      getList: "showcases/getList",
    }),
    onClickLogout() {
      this.logout();
      if (!this.isLoggedIn) {
        this.$router.push({ name: "auth" });
      }
    },
  },
};
</script>
