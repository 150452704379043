export default {
  namespaced: true,
  state: () => ({
    info: null,
  }),
  actions: {
    login({ commit }, { login, password }) {
      return this.$infra.adminApi.auth
        .login({ login, password })
        .then((response) => {
          if (response.successful) {
            commit("setInfo", response.result);
            return response.result;
          }

          throw response;
        })
        .catch((res) => {
          if (res.message) {
            alert(res.message);
          }

          console.error("login error: ", res);

          throw res;
        });
    },
    getUser({ commit }) {
      return this.$infra.adminApi.auth.user().then((response) => {
        if ((response || {}).successful) {
          commit("setInfo", response.result);
          return response.result;
        }

        if (response) {
          throw response;
        }
      });
    },
    logout({ commit }) {
      if (localStorage.setItem) {
        localStorage.setItem("token", "");
      }
      commit("setInfo", null);
    },
  },
  mutations: {
    setInfo(state, info) {
      state.info = info;
    },
  },
  getters: {
    isLoggedIn(state) {
      return Boolean(state.info);
    },
    login(state) {
      return (state.info || {}).login;
    },
  },
};
